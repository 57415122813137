// Api URL based on env
export const API_URL = "/api";

export const ROLES = [
  {value: "admin", label: "Admin"},
  {value: "user", label: "Utente base"}
]

export const CALENDAR_MODE = {
  DAILY: "daily",
  WEEKLY: "weekly",
  MONTHLY: "monthly"
}

// #!STANDARD
export const sankeyColor = [
  { id: "NEW-FRAUD", color: "#F28482", label: "New Fraud" },
  { id: "PERSISTENT", color: "#F6BD60", label: "Persistent" },
  { id: "GONE", color: "#84A59D", label: "Gone" },
  { id: "END", color: "#84A59D", label: "End" },
  { id: "FAKE", color: "#FFFFFF", label: "End" },
];
export const defaultBKColor = "#fefbf2";
