import React, {createContext, useState} from 'react';

const DataContext = createContext({});
const {Provider} = DataContext;


const DataProvider = ({children}) => {

  // ### PRODUCTS ###
  const [products, setProducts] = useState([]);
  const [prodMediaTopic, setProdMediaTopic] = useState();
  const [mediaTopicsTree, setMediaTopicsTree] = useState();

  // ### CAMPAIGNS ###
  const [campaignsData, setCampaignsData] = useState();
  const [tableCampaignsData, setTableCampaignsData] = useState();

  // ### SEGMENTS ###
  const [tableSegmentsData, setTableSegmentsData] = useState();

  // ### RETENTION ###
  const [optionsProducts, setOptionsProducts] = useState();

  // ### CHURN REPORT ###
  // options
  const [optionsCombo, setOptionsCombo] = useState([]);
  const [optionsSubType, setOptionsSubType] = useState([]);
  const [optionsCol2Group, setOptionsCol2Group] = useState([]);


  return (
    <Provider
      value={{
        products, setProducts: (v) => setProducts(v),
        prodMediaTopic, setProdMediaTopic: (v) => setProdMediaTopic(v),
        mediaTopicsTree, setMediaTopicsTree: (v) => setMediaTopicsTree(v),
        campaignsData, setCampaignsData: (v) => setCampaignsData(v),
        tableCampaignsData, setTableCampaignsData: (v) => setTableCampaignsData(v),
        tableSegmentsData, setTableSegmentsData: (v) => setTableSegmentsData(v),
        optionsProducts, setOptionsProducts: (v) => setOptionsProducts(v),
        optionsCombo, setOptionsCombo: (v) => setOptionsCombo(v),
        optionsSubType, setOptionsSubType: (v) => setOptionsSubType(v),
        optionsCol2Group, setOptionsCol2Group: (v) => setOptionsCol2Group(v),
      }}
    >
      {children}
    </Provider>
  );
};

export {DataContext, DataProvider}
