const en_custom = {
  absolute_value: "Absolute Values",
  add_products: "Add products",
  add_taxonomy: "Add Taxonomy",
  admin: "Amministratore",
  aggregate_fraudolent_customers: "Aggregate Fraudolent Customers",
  aggregate_fraudolent_customers_explaination: "Aggregated information of fraudulent customers in a given range of time",
  base_viewer: "Base viewer",
  code: "Code",
  comparison_by_product: "Comparison by Product",
  comparison_by_year: "Comparison by Year",
  customer_journey: "Customer Journey",
  customer_journey_KPI: "Customer Journey KPI",
  customers: "Customers",
  date: "Date",
  detection_threshold: "Detection Thresholds",
  devices: "Devices",
  download_logs: "Download logs",
  end_date: "End Date",
  fraud_detection: "Fraud Detection",
  fraud_logs_last_months: "Fraudulent behavior logs for last 6 months",
  fraudolent_customers_by_product: "Fraudolent Customers by Product",
  fraudolent_customers_by_product_explaination: "Number of fraudulent customers grouped by month and product in a given range of time",
  fraudolent_customers_comparison_by_year: "Fraudolent Customers Comparison by Year",
  fraudolent_customers_comparison_by_year_explaination: "Number of fraudulent customers of a specific product grouped by month and compared between last two years in a given range of time",
  fraudolent_customers_flow_comparison_by_product: "Fraudolent Customers Flows Comparison by Product",
  fraudolents_from: "Fraudolent from",
  frauds_viewer: "Fraud viewer",
  GC: "Large Customers",
  graph_type: "Graph type",
  ip: "IP",
  kpi_type: "KPI type",
  large_customers: "Large customers",
  manager: "Manager",
  month: "Month",
  months: "Months",
  no_fraudolent_users_time_interval: "There are no fraudulent users for this product in this time interval",
  page_visible_only_to_admins: "This page is visible only to admins, for testing purposes",
  percentage: "Percentage",
  product: "Product",
  product_category: "Product Category",
  products_and_taxonomy: "Products & Taxonomy",
  products_list: "Products List",
  select_kpi: "Select a KPI",
  select_one_product_from_list: "Select a product from the list",
  select_one_product_from_list_to_edit_taxonomy: "Select one product from the list to edit its taxonomy",
  select_product: "Select a product",
  select_products: "Select products",
  sessions: "Sessions",
  ST: "Standard",
  standard_customers: "Standard customers",
  start_date: "Start Date",
  taxonomy: "Taxonomy",
  taxonomy_added_manually: "Taxonomy added manually",
  taxonomy_delete: "Delete taxonomy",
  taxonomy_disabled_manually: "Taxonomy disabled manually",
  taxonomy_reenable: "Re-enable this taxonomy",
  taxonomies: "Taxonomy",
  update: "Update",
  update_error: "Update failed",
  update_success: "Update successful",
  user_type: "User Type",
  year: "Year",
};

export default en_custom;