import React from "react";
import {
  ExclamationTriangleIcon,
  HomeIcon,
  TagIcon,
} from "@heroicons/react/24/outline";
const Home = React.lazy(() =>
  import(/* webpackChunkName: "home" */ "@pages/Home")
);

const Products = React.lazy(() =>
  import(/* webpackChunkName: "products" */ "@pages/Products")
);

const FraudDetection = React.lazy(() =>
  import(/* webpackChunkName: "fraud_detection" */ "@pages/FraudDetection")
);

const routes = [
  {
    name: "Home",
    href: "/home",
    Icon: HomeIcon,
    component: Home,
    hidden: false,
    scopes: ["home:read"], /* !STANDARD, visibile only to admins/manager, for tests */
  },
  {
    name: "fraud_detection",
    href: "/fraud_detection",
    Icon: ExclamationTriangleIcon,
    component: FraudDetection,
    hidden: false,
    scopes: ["fraud:read"],
  },
  {
    name: "products_and_taxonomy",
    href: "/products",
    Icon: TagIcon,
    component: Products,
    hidden: false,
    scopes: ["product:read"],
  },
];

export default routes;
