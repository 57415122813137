import React from "react";
import {TypesLogoProps} from "./logo.types";

/* !STANDARD, logo component edited to show the title on the right or bottom, if titlePosition is set */
const Logo = ({height = "h-24", width = "w-auto", imagePath = process.env.PUBLIC_URL + "/logo.svg", titlePosition}: TypesLogoProps) =>
<div className={`flex ${titlePosition === "right" ? 'items-center' : 'flex-col'} ${titlePosition === "bottom" ? 'mt-4' : 'my-3 px-2'}`}>
  <img
    className={`${height} ${width} object-contain mx-auto`}
    src={imagePath}
    alt="ammagamma logo"
  />
  {Boolean(titlePosition) && <div className={`${titlePosition === "right" ? 'ml-4' : 'text-center'} text-lg font-bold`}>Dashboard Marketing</div>}
</div>

export default Logo;